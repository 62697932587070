<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
  >
    <div class="form-group mb-0 break-spaces">
      <v-text-area
        id="preAnamnese"
        :value="form.fields.preAnamnese"
        @blur="updateMedicalRecord(form)"
        @inputTextArea="el => updateForm('preAnamnese', el)"
        :readonly="!canEdit"
        rows="1"
        placeholder="Descrever"
      />
    </div>
  </FormWrapper>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import FormWrapper from '@/components/Attendance/Forms/FormWrapper'
import TextArea from '@/components/General/TextArea'

export default {
  components: {
    FormWrapper,
    'v-text-area': TextArea,
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.preAnamnese,
    })
  },
  methods: {
    ...mapActions('attendance/form', ['handleActiveModule', 'updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/preAnamnese', ['handleFields', 'handleProps']),
    updateForm(key, value) {
      this.handleFields({ key, value })
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      Object.keys(this.form.fields).forEach(key => {
        this.form.fields[key] = null
      });
    }
  }
}
</script>
